import { Container } from '@material-ui/core';
import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { MenuBaseList } from '../menu/MenuBaseList';
import {
  Bar, Buttons, Icons, useUIContext,
} from '../../../../common/components';

type baseScreenProps = {
  children: JSX.Element[] | JSX.Element;
};

const containerStyle = {
  frame: {
    width: '100%',
    height: 'calc( 100vh - 64px )',
    padding: 0,
    margin: 0,
    marginTop: 64,
  },
};

export const BaseScreen = (props:baseScreenProps) => {
  const { logout } = useUIContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Bar>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => { logout(); }}>
            {' '}
            <ListItemIcon><Icons.IconByName name="logout" /></ListItemIcon>
            Cerrar Sesion
          </MenuItem>
        </Menu>
        <Buttons.IconButton icon="account_circle" onClick={openMenu} />
      </Bar>
      <MenuBaseList isOpen />
      <Container style={containerStyle.frame}>
        {props.children}
      </Container>
    </>
  );
};
