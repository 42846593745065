import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useStyles } from './style';
import { Connection } from '../../api/Connection';
import { Working } from '../../../application/screen/system/loading/Working';
import { getGuestSession, Session } from '../../api/Session';
import { RetoolMenuGroup } from './retool/RetoolMenu';

type MenuState = {
  isOpen:boolean,
  isVisible:boolean
};

/**
 *
 */
const menuState: MenuState = {
  isOpen: true,
  isVisible: true,
};
const toggleMenuFn = () => { menuState.isOpen = !menuState.isOpen; };
const showMenuFn = () => { menuState.isVisible = true; };
const hideMenuFn = () => { menuState.isVisible = false; };

/**
 *
 * @param state
 */
type LoggedState = {
  isLogged:boolean;
};
const loggedState:LoggedState = {
  isLogged: false,
};

const setLoggedFn = (state:boolean) => { loggedState.isLogged = state; };

export type UIContextType = {
  [k:string]:any,
  menu:MenuState,
  isLogged:boolean
  setLogged:(state:boolean)=>void,
  session:Session,
  setSession:(session:Session)=>void,
  logout:()=>void,
  working:boolean,
  retool_menus:RetoolMenuGroup[]
  toggleMenu():void,
  showMenu():void,
  hideMenu():void;
};
/**
 *
 */
export const UIContext = React.createContext<UIContextType>({
  session: getGuestSession(),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSession(session: Session): void {},
  working: false,
  menu: menuState,
  toggleMenu: toggleMenuFn,
  showMenu: showMenuFn,
  hideMenu: hideMenuFn,
  setLogged: setLoggedFn,
  retool_menus: [],
  logout():void {},
  isLogged: false,
});
/**
 *
 */
export type UIContextProviderProps = {
  children: JSX.Element[] | JSX.Element
};
/**
 *
 * @param props
 * @constructor
 */
export const UIContextProvider = (props:UIContextProviderProps) => {
  const style = useStyles();
  const [working, setWorking] = useState(true);
  const [retool_menus, setRetoolMenus] = useState<RetoolMenuGroup[]>([]);
  const [session, setSession] = useState<Session>(getGuestSession());
  const [menu, setMenu] = useState({
    isOpen: true,
    isVisible: true,
  });
  const [isLogged, setLogged] = useState(false);
  // Check session
  const checkSession = useCallback(() => {
    async function check() {
      try {
        const _session = await Connection.hasSession();
        setSession(_session);
        setLogged(!_session.isGuest);
      } finally {
        setWorking(false);
      }
    }
    check();
  }, []);
  useEffect(() => checkSession(), [checkSession]);
  // Check menus
  const grabMenus = useCallback(() => {
    async function getMenus():Promise<void> {
      const menus = await Connection.getMenus();
      setRetoolMenus(menus);
    }
    getMenus();
  }, []);

  useEffect(() => {
    console.log('MENU LOADER', 'loaded menus');
    if (session.isGuest) {
      console.debug('Not loading');
      return;
    }
    grabMenus();
  }, [grabMenus, session]);

  const toggleMenu = useCallback(() => {
    setMenu((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  }, []);
  const showMenu = useCallback(() => { setMenu((prev) => ({ ...prev, isVisible: true })); }, []);
  const hideMenu = useCallback(() => { setMenu((prev) => ({ ...prev, isVisible: false })); }, []);
  const logout = useCallback(() => {
    Connection.cleanSession();
    checkSession();
  }, [checkSession]);
  const value = useMemo(() => ({
    style, menu, toggleMenu, showMenu, hideMenu, isLogged, setLogged, working, session, setSession, logout, retool_menus, setRetoolMenus,
  }), [style, menu, toggleMenu, showMenu, hideMenu, isLogged, setLogged, working, session, setSession, logout, retool_menus, setRetoolMenus]);
  return (

    <UIContext.Provider value={value}>
      <Working working={working}>
        {props.children}
      </Working>
    </UIContext.Provider>

  );
};
