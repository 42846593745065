import React from 'react';
import { RetoolMenu } from '../../../../common/components/ui/retool/RetoolMenu';
import { Retool } from '../../../../common/components/ui/retool/Retool';

type RetoolScreenProps = {
  config:RetoolMenu
};
export function RetoolScreen(props:RetoolScreenProps) {
  return <Retool url={props.config.retool_app} />;
}
