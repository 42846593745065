import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Buttons } from '../button';
import { useUIContext } from '../useUIContext';

export type BarProps = {
  children?: JSX.Element[] | JSX.Element,
};

export const Bar = (props:BarProps) => {
  const { menu, style, toggleMenu } = useUIContext();
  return (
    <AppBar position="fixed" className={!menu.isOpen ? style.appBar : style.appBarShift}>
      <Toolbar>
        <Box display="flex" flexGrow={1}>
          <Buttons.IconButton icon="menu" onClick={toggleMenu} />
        </Box>
        {props.children}
      </Toolbar>
    </AppBar>
  );
};
