import React from 'react';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { Drawer } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import { Buttons, Icons, useUIContext } from '../../../../common/components';

type MenuBaseProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  isOpen:boolean
};

type MenuOptionsProps = {
  icon:string;
  path:string;
  text:string
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  menuItem: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  menuTitle: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightLight,
    marginLeft: 5,
    marginTop: 5,
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));

function MenuOption(props:MenuOptionsProps) {
  const history = useHistory();
  const classes = useStyles();
  const { toggleMenu } = useUIContext();
  /* const callback = useCallback(()=>{
        history.push( props.path)
    },[history]); */

  const callback = () => { history.push(props.path); toggleMenu(); };
  return (

    <MenuItem onClick={callback}>
      <ListItemIcon>
        <Icons.IconByName name={props.icon} />
      </ListItemIcon>
      <Typography className={classes.menuItem}>{props.text}</Typography>
    </MenuItem>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function MenuBaseList(props:MenuBaseProps) {
  const classes = useStyles();
  const {
    style, session, menu, toggleMenu, retool_menus,
  } = useUIContext();

  return (
    <Drawer className={style.drawer} variant="persistent" anchor="left" open={menu.isOpen} classes={{ paper: style.drawerPaper }}>
      <div className={style.drawerHeader}>
        <Buttons.IconButton icon="chevron_left_icon" onClick={toggleMenu} />
      </div>
      <div style={{ padding: 15, marginTop: -65, minHeight: 135 }}>
        <Avatar className={classes.purple}>
          {session.user.name.toUpperCase().substring(0, 1)}
          {session.user.lastname.toUpperCase().substring(0, 1)}
        </Avatar>
        <Typography variant="subtitle1" style={{ marginTop: 10 }}>{`${session.user.name} ${session.user.lastname}`}</Typography>
        <Typography variant="caption">{session.user.email}</Typography>

      </div>
      <Divider />
      {
                retool_menus.map((group, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Collapse key={`${group.name}-${index}}`} in timeout="auto" unmountOnExit style={{ paddingLeft: 0 }}>
                    <Typography className={classes.menuTitle}>{group.name}</Typography>

                    <MenuList>
                      {
                        // eslint-disable-next-line react/no-array-index-key
                               group.menus.map((_menu, _index) => <MenuOption key={`${_menu.name}-${_index}`} icon={_menu.icon} path={_menu.path} text={_menu.name} />)
                            }
                    </MenuList>
                    <Divider />
                  </Collapse>
                ))
            }
    </Drawer>
  );
}
