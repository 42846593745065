import React from 'react';
import { IconButton } from '@material-ui/core';
import { Icons } from '../icon';

export type ButtonProps = {
  children: JSX.Element[] | JSX.Element,
  onClick?(event:React.MouseEvent<HTMLButtonElement>):void
};

export type ButtonIconProps = Omit<ButtonProps, 'children'> & { icon:string };

class Buttons {
  IconButton(props:ButtonIconProps) {
    return (
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={props.onClick}>
        <Icons.IconByName name={props.icon} />
      </IconButton>
    );
  }
}

const i:Buttons = new Buttons();
export { i as Buttons };
