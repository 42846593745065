import React from 'react';
import { Icon } from '@material-ui/core';

export type IconProps = {
  name?:string
};

class Icons {
  IconByName(props:Required<IconProps>) {
    return (<Icon>{props.name}</Icon>);
  }

  MenuIcon(props:IconProps) {
    return (<Icon {...props}>menu</Icon>);
  }
}

const i = new Icons();
export { i as Icons };
