import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { BaseScreen } from '../screen';
import { LoginScreen } from '../screen/system/login/LoginScreen';
import { useUIContext } from '../../common/components';
import { RetoolScreen } from '../screen/business/retool/RetoolScreen';

interface RouteProps {
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Routes = (props:RouteProps) => {
  const { working, session, retool_menus } = useUIContext();

  return working ? <></> : !session.isGuest ? (
    <BaseScreen>
      <Switch>
        {
                        retool_menus.map((group) => (
                          group.menus.map((menu, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Route key={`${menu.name}-${index}`} path={menu.path}><RetoolScreen config={menu} /></Route>
                          ))
                        ))
                    }
        <Redirect to="/home" />
      </Switch>
    </BaseScreen>
  )
    : (<LoginScreen />);
};
