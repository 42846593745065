import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export type WorkingProps = {
  working:boolean;
  children: JSX.Element[] | JSX.Element;
};
export function Working(props:WorkingProps) {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  return (
    <>
      <Backdrop className={classes.backdrop} open={props.working} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {props.children}
    </>
  );
}
