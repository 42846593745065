import React from 'react';
import { useUIContext } from '../useUIContext';

const styles = {
  height: '100vh',
  width: '100vw',
  webkitScrollbar: {
    display: 'none',
  },
};
type retoolProps = {
  url:string
};

export function Retool(props:retoolProps) {
  const { session } = useUIContext();

  return (
    <iframe
      title="el title"
      style={styles}
      frameBorder="none"
      src={`${props.url}&token=${session.user.token}`}
    />
  );
}

/*
class Retool extends React.Component<retoolProps,{url:string,elementWatchers:any}> {
    protected iframe:any;

    constructor(props:retoolProps) {
        super(props);

        if (!this.props.url) throw new Error('Please pass a url into the Retool component.')

        this.state = {
            url: props.url,
            elementWatchers: {}
        }
    }

    componentDidMount(){
     //   this.startListening()
      //  this.startWatchers()
    }

    startListening = () => {
        if (this.iframe) {
            window.addEventListener('message', (e) => this.handle(e) );
        }
    }

    startWatchers = () => {
        var watcherKeys = Object.keys(this.state.elementWatchers)

        for (var i = 0; i < watcherKeys.length; i++) {
            var key = watcherKeys[i]
            var watcher = this.state.elementWatchers[key]
            var selector = watcher.selector
            var node = document.querySelector(selector)
            var value = node?.textContent
            if (value !== watcher.prevValue) {
                watcher.prevValue = value
                watcher.iframe.contentWindow.postMessage(
                    {
                        type: 'PARENT_WINDOW_RESULT',
                        result: value,
                        id: watcher.queryId,
                        pageName: watcher.pageName,
                    },
                    '*',
                )
            }
        }

        setTimeout(this.startWatchers, 100)
    }

    createOrReplaceWatcher = (selector:any, pageName:any, queryId:any) => {
        var watcherId = pageName + '-' + queryId
        var watchers = {...this.state.elementWatchers}

        watchers[watcherId] = {
            iframe: this.iframe,
            selector: selector,
            pageName: pageName,
            queryId: queryId,
            prevValue: null,
        }

        this.setState({elementWatchers: watchers})
    }

    handle = (event:any) => {
        if (!this.iframe.contentWindow) return

        var node;

        if (event.data.type === 'PARENT_WINDOW_QUERY') {
            node = document.querySelector(event.data.selector)
            this.createOrReplaceWatcher(event.data.selector, event.data.pageName, event.data.id)

            this.iframe.contentWindow.postMessage(
                {
                    type: 'PARENT_WINDOW_RESULT',
                    result: node?.textContent,
                    id: event.data.id,
                    pageName: event.data.pageName,
                },
                '*',
            )
        }

        if (event.data.type === 'PARENT_WINDOW_PREVIEW_QUERY') {
            node = document.querySelector(event.data.selector)
            this.iframe.contentWindow.postMessage(
                {
                    type: 'PARENT_WINDOW_PREVIEW_RESULT',
                    result: node?.textContent,
                    id: event.data.id,
                },
                '*',
            )
        }

    }

    render() {
        return (
            <iframe
                style={styles}
                frameBorder="none"
                src={this.state.url}
                ref = {e => {
                    this.iframe = e
                }}
            >
            </iframe>
        );
    }
}

export default Retool;
*/
