import React from 'react';
import { UIContextProvider } from './common/components';

import { Routes } from './application/routes/Routes';

export const App = () => (
  <UIContextProvider>
    <Routes />
  </UIContextProvider>
);
