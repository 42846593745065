import React, { useCallback, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { useUIContext } from '../../../../common/components';
import { Connection } from '../../../../common/api/Connection';
import { Working } from '../loading/Working';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>
      {' '}
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export function LoginScreen() {
  const history = useHistory();
  const { setSession } = useUIContext();
  const [working, setWorking] = useState<boolean>(false);
  const [remember, setRemember] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [nick, setNick] = useState<string>(localStorage.getItem('nick') || '');
  const [password, setPassword] = useState<string>('');
  const classes = useStyles();

  const doLogin = useCallback(async (event) => {
    event.preventDefault();
    try {
      setWorking(true);
      const session = await Connection.login(nick, password);
      if (remember) localStorage.setItem('nick', nick);
      setSession(session);
      history.push('/');
    } catch (e) {
      setError(e.toString());
    } finally {
      setWorking(false);
    }
  }, [history, nick, password, remember, setSession]);

  const canSubmit = nick.length > 3 && password.length > 3;
  return (
    <Working working={working}>
      <Snackbar open={error !== ''} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={doLogin}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={nick}
              onInput={(e) => setNick((e.target as HTMLInputElement).value)}
              id="nick"
              label="Usuario"
              name="nick"
              autoComplete="nick"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              onInput={(e) => setPassword((e.target as HTMLInputElement).value)}
              value={password}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              label="Remember me"
              control={(
                <Checkbox
                  color="primary"
                  onChange={(e) => {
                    setRemember((e.target as HTMLInputElement).checked);
                  }}
                  checked={remember}
                />
              )}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!canSubmit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/#" variant="body2">
                  Don&apost have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </Working>
  );
}
