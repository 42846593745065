import Joi from 'joi';

export type User = {
  'email':string,
  'groups': [],
  'id': string,
  'isGuest': boolean,
  'lastname': string,
  'name': string,
  'nick': string,
  'username': string,
  'token': string,
  'permissions':string[]
};

export type Session = {
  'user': User,
  'isGuest': boolean;
};

const SessionSchema = Joi.object({
  user: Joi.object({
    email: Joi.string().allow(''),
    groups: Joi.array(),
    id: Joi.string().strict().required(),
    isGuest: Joi.boolean().strict().required(),
    lastname: Joi.string().strict().required(),
    name: Joi.string().strict().required(),
    nick: Joi.string().strict().required(),
    username: Joi.string().strict().required(),
    token: Joi.string().strict().required().allow(''),
    permissions: Joi.array().strict().required(),
  }),
  isGuest: Joi.boolean().strict().required(),
});

export function getGuestSession():Session {
  return {
    isGuest: true,
    user: {
      email: '',
      groups: [],
      id: '',
      isGuest: true,
      lastname: 'Guest',
      name: 'Guest',
      nick: 'Guest',
      username: 'Guest',
      token: '',
      permissions: [],
    },
  };
}

export function isValidSession(session:any):Session {
  const result = SessionSchema.validate(session);
  console.log(result.error);
  if (result.error) throw new Error('Not a valid session');
  return session as Session;
}
